@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://use.typekit.net/hod1gww.css');
/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,300;0,400;0,600;0,700;0,900;1,400&display=swap');

:root {
  --header-height: 64px;
  --header-search-height: 38px;
  --header-search-radius: 20px;
  --solid-black: #000;
  --solid-white: #fff;
  --color-red: #f2123d;
  --color-blue: #4adec7;
  --color-grey: #d1d1de;
  --color-gold: #f2ba59;
  --color-black: #241b23;
}
/* 
* {
  margin: 0;
  padding: 0;
} */
/* 
html, body {
  background-color: black;
  max-width: 100%;
  overflow-x: hidden;
}

@media (min-width: 992px) {
  html, body {
    height: 100vh;
  }
} */

#root {
  overflow: hidden;
  width: 100%;
}

body {
  font: 15px/1.5 'Noto Sans', Arial, Helvetica, sans-serif;
  background: var(--solid-black);
  color: var(--solid-white);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
button {
  line-height: 1.1;
}

.App {
  padding-top: var(--header-height);
  position: relative;
}

.logo {
  width: 150px;
}

input[type='text'] {
  &:focus {
    box-shadow: none;
    outline: none;
  }
}

@media only screen and (max-width: 1140px) {
  .logo {
    padding: 8px;
  }
}

.forminput {
  width: 100%;
  background: #fff;
  outline: none !important;
  border: none !important;
  padding: 1px 18px 3px;
  display: block;
  margin: 0;
  color: var(--solid-black);
}

.container {
  max-width: 1600px;
  padding-left: 25px;
  padding-right: 25px;
  margin-left: auto;
  margin-right: auto;
}

.headersearch .forminput {
  height: var(--header-search-height);
  border-radius: var(--header-search-radius) 0 0 var(--header-search-radius);
}

.headersearch select.forminput {
  border-radius: 0 var(--header-search-radius) var(--header-search-radius) 0;
  background-color: #212121;
  color: #fff;
  appearance: none;
}

.headerbutton {
  background-color: var(--color-red);
  height: var(--header-search-height);
}

.selectwrap:before {
  content: '';
  position: absolute;
  right: 12px;
  top: 50%;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNi4wLjIsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA0Ny4zIDI3LjIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ3LjMgMjcuMjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6I0ZGRkZGRjt9DQo8L3N0eWxlPg0KPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSI0My44LDAgMjMuNywyMC4yIDMuNSwwIDAsMy41IDIzLjcsMjcuMiA0Ny4zLDMuNSAiLz4NCjwvc3ZnPg0K')
    no-repeat;
  height: 8px;
  width: 14px;
  background-size: 100% auto;
  margin-top: -4px;
}

.icon {
  display: inline-block;
  vertical-align: top;
  width: 20px;
  height: auto;
  fill: currentColor;
}

.sidebarmenu .icon {
  width: 25px;
}

.sidebarmenu a:hover,
.sidebarmenu a.active {
  color: var(--color-red);
}

.menulinks a {
  position: relative;
}

.menulinks a:before {
  content: '';
  position: absolute;
  left: 0;
  height: 1px;
  width: 0;
  bottom: -8px;
  transition: width 0.3s;
  background: var(--color-red);
}

.menulinks a.active:before {
  width: 100%;
}

// .w-[98px] {
//   width: 98px;
// }

.MuiOutlinedInput-input.MuiInputBase-input {
  height: 39px !important;
  color: #fff;
  outline: none !important;
  border: none !important;
  box-sizing: border-box;
  padding: 0;
  font-size: 14px;
}

.MuiOutlinedInput-root.MuiInputBase-root,
.MuiFormControl-root.MuiTextField-root,
.MuiOutlinedInput-notchedOutline {
  outline: none !important;
  border: none !important;
}

@media only screen and (max-width: 767px) {
  .sticky-outer-wrapper {
    height: auto !important;
    position: relative;
  }

  .sticky-inner-wrapper {
    width: 100% !important;
    position: relative !important;
    transform: none !important;
    z-index: 99;
  }

  /* .sidebarmenu {
    display: none !important;
  } */
}

.hasendshadow:before {
  content: '';
  position: absolute;
  right: 0;
  width: 200px;
  top: 0;
  bottom: 0;
  box-shadow: inset -70px 0 30px -40px rgba(0, 0, 0, 0.98);
  z-index: 4;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background: var(--color-red);
}

/* .App {
  max-width: 100%;
  overflow-x: hidden;

}

@media (min-width: 768px) {
  .App {
    padding: 0 28px;
  }
} */

// .swiper-wrapper {
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   flex-wrap: wrap;
// }

// .swiper-container {
//   margin: 0;
// }

// .autowidth .swiper-slide {
//   width: auto !important;
// }

.swiper .swiper-slide {
  display: flex;
  width: 98px;
}

/* Mobile Only CSS */
@media (max-width: 768px) {
  .App {
    max-width: 100%;
    overflow-x: hidden;
  }

  .swiper .swiper-slide {
    width: 64px;
  }
}

.hide {
  display: none;
}

/* Messages Styling */
.ce-chat-form-container {
  background-color: black !important;
  color: white !important;
}

.ce-chats-container {
  background-color: black !important;
  color: white !important;
}

.ce-chat-card {
  background-color: black !important;
  color: white !important;
}

.ce-active-chat-card {
  background-color: #f2133c !important;
  color: white !important;
}

.ce-chat-title,
.ce-settings {
  background-color: black !important;
  color: white !important;
}

#ce-ice-breaker,
#ce-feed-container {
  background-color: black !important;
  color: white !important;
}

// Hide Scrollbar - Welcome screen scrollable columns
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.reactEditorWrapper {
  border: 1px solid #ddd;
  border-radius: 5px;
}

// WYSIWYG Specificity fix
.box-border-important {
  box-sizing: border-box !important;
}

.rdw-editor-main {
  min-height: 250px;
}

.rdw-text-align-dropdown {
  z-index: 9 !important;
}

.custom__productcheck {
  position: relative;
  width: 100px;

  &:hover {
    .img__wrap {
      &:before {
        opacity: 1;
      }
    }
  }

  input[type="checkbox"] {
    position: absolute;
    left: 2px;
    top: 2px;
    z-index: 2;
    opacity: 0;

    &:checked {
      opacity: 1;

      + label .img__wrap:before {
        opacity: 1;
      }
    }
  }

  label {
    display: block;
    cursor: pointer;
  }

  .img__wrap {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(242,18,61, 0.3);
      border-radius: 5px;
      opacity: 0;
    }
  }
}